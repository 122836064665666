import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../src/App.css';
import AppHerode from '../de_hero';
import AppFooterde from '../de_footer';
import Appheaderde from '../de_header';
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



const teamsData = [
  {
    id: 1,
    imgSrc: require("../../assets/images/glasfaser_untertitel.mp4"),
    link: "/en/casestudy1",
    title: "Glasfasernetzwerk",
    imgAlt: "Glasfasernetzwerk",
    description: "Bei der Planung von Glasfasernetzen nutzt CONTRA GIS-Daten, Haushaltskoordinaten und bodenbezogene Verlegungskosten. Unsere Software wählt dann eine Zielfunktion, sei es die Minimierung der Baukosten oder die Verkleinerung der Netzgröße. Dank der Automatisierung der Datenauswertung generieren wir ein optimales Netzwerk, in dem entweder die Verlegungskosten oder die Netzwerklänge zwischen den Gebäuden minimiert werden.",
    mobileDescription: "Bei der Planung von Glasfasernetzen nutzt CONTRA GIS-Daten, Haushalts-koordinaten und bodenbezogene Ver-legungskosten. Unsere Software wählt dann eine Zielfunktion, sei es die Minimierung der Baukosten oder die Verkleinerung der Netzgröße. Dank der Automatisierung der Datenauswertung generieren wir ein optimales Netzwerk, in dem entweder die Verlegungskosten oder die Netzwerklänge zwischen den Gebäuden minimiert werden.",
  },
  {
    id: 2,
    imgSrc: require("../../assets/images/seeroute_deutsch_untertitel.mp4"),
    link: "/en/casestudy1",
    title: "Seeroutenplanung",
    imgAlt: "Seeroutenplanung",
    description: "Mit präzisen Algorithmen und datengesteuerten Strategien zeichnet sich CONTRA durch die Berechnung von Seerouten aus, die nicht die kürzesten, aber die effektivsten und schnellsten sind. Durch die Nutzung der Leistungsfähigkeit von GRIB-Daten können wichtige maritime Informationen wie Schiffsgeschwindigkeit, Seegang und mögliche Hindernisse berücksichtigt werden. Dieser umfassende Ansatz stellt sicher, dass Frachtschiffe auf Routen geleitet werden, die maximale Effizienz garantieren.",
    mobileDescription: "Mit präzisen Algorithmen und datengesteuerten Strategien zeichnet sich CONTRA durch die Berechnung von Seerouten aus, die nicht die kürzesten, aber die effektivsten und schnellsten sind. Durch die Nutzung der Leistungsfähigkeit von GRIB-Daten können wichtige maritime Informationen wie Schiffsgeschwindigkeit, Seegang und mögliche Hindernisse berücksichtigt werden. Dieser umfassende Ansatz stellt sicher, dass Frachtschiffe auf Routen geleitet werden, die maximale Effizienz garantieren.",
  },
  {
    id: 3,
    imgSrc: require("../../assets/images/German antenna.mp4"),
    link: "/en/casestudy1",
    imgAlt: "5G-Funkmasten",
    title: "5G-Funkmasten",
    description: "Die intelligenten Algorithmen von CONTRA übernehmen die optimale und strategisch wertvolle Platzierung von Antennen, die für eine umfassende Mobilfunknetzabdeckung von entscheidender Bedeutung sind. Dadurch werden die Kosten nachhaltig und naturschonend reduziert und weniger Funkmasten errichtet. Unsere Software analysiert Gebiete Punkt für Punkt, bestimmt die ideale Antennenverteilung und wählt die effizienteste Lösung für eine umfassende Funkabdeckung aus.",
    mobileDescription: "Die intelligenten Algorithmen von CONTRA übernehmen die optimale und strategisch wertvolle Platzierung von Antennen, die für eine umfassende Mobilfunknetzabdeckung von entscheidender Bedeutung sind. Dadurch werden die Kosten nachhaltig und naturschonend reduziert und weniger Funkmasten errichtet. Unsere Software analysiert Gebiete Punkt für Punkt, bestimmt die ideale Antennenverteilung und wählt die effizienteste Lösung für eine umfassende Funkabdeckung aus.",
  },
  
]

function Apphomede() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <header id="header">
        <Appheaderde />
      </header>
      <main>
        <AppHerode />

        {/* Content Section */}
        <div className="content-container p-4 space-y-8">
          {teamsData.map((team) => (
            <div key={team.id} className="bg-white rounded-lg shadow-md ">
              <h3 className="text-2xl font-semibold mb-2 text-left p-3">{team.title}</h3>
              
              {/* Render video if file extension is .mp4, otherwise render img */}
              <div className="flex justify-center p-2">
                {team.imgSrc.includes('.mp4') ? (
                  <video className="w-full max-w-5xl h-auto rounded-lg" autoPlay muted loop controls style={{ maxWidth: "1300px" }}>
                    <source src={team.imgSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={team.imgSrc} alt={team.imgAlt} className="w-full max-w-5xl h-auto rounded-lg" style={{ maxWidth: "1300px" }} />
                )}
                
              </div>

              <p className="text-lg font-normal text-justify mb-4 text-gray-700 p-3">{team.description}</p>
            </div>
          ))}
        </div>
        <AppFooterde />
      </main>
    </div>
  );
}

export default Apphomede;


